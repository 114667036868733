import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "./firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

function Events() {
  const [events, setEvents] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events1");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map((doc, index) => ({
        id: index + 1,
        title: doc.data().title,
        artist: doc.data().artist,
        date: doc.data().date.toDate().toLocaleDateString(),
        category: doc.data().category,
        docId: doc.id,
      }));
      setEvents(eventsList);
      console.log(eventsList);
    };

    fetchEvents();
  }, []);

  const handleEdit = (event) => {
    navigate(`/events/edit-event/${event.docId}`);
  };

  const handleDelete = async (event) => {
    // Handle the delete action
    console.log(`Deleting event: ${event.docId}`);
    const eventDoc = doc(db, "events1", event.docId);
    await deleteDoc(eventDoc);
    setEvents(events.filter((e) => e.docId !== event.docId));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "title", headerName: "Title", width: 130 },
    { field: "artist", headerName: "Artist", width: 130 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "category", headerName: "Category", width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <button
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </button>
          <button onClick={() => handleDelete(params.row)}>Delete</button>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          height: 400,
          width: "100%",

          padding: "50px",
        }}
      >
        <DataGrid
          rows={events}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </div>
    </>
  );
}

export default Events;
