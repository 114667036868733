import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import { toBeValid } from "@testing-library/jest-dom/matchers";

function EditEventForm() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const eventDoc = doc(db, "events1", id);
      const eventSnapshot = await getDoc(eventDoc);
      setEvent({ ...eventSnapshot.data(), id: eventSnapshot.id });
    };

    fetchEvent();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update the event in Firestore
    const eventDoc = doc(db, "events1", id);
    await updateDoc(eventDoc, event);
  };

  if (!event) return "Loading...";

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="event-form"
        style={{
          backgroundColor: "var( --whiteText-color)",
          padding: "50px",
          borderRadius: "20px",
        }}
      >
        <TextField
          label="Artist"
          type="text"
          value={event.artist}
          onChange={(e) => setEvent({ ...event, artist: e.target.value })}
        />
        <TextField
          label="Title"
          type="text"
          value={event.title}
          onChange={(e) => setEvent({ ...event, title: e.target.value })}
        />

        <TextField
          value={event.description}
          onChange={(e) => setEvent({ ...event, description: e.target.value })}
          label="Description"
          multiline
          rows={4}
        />
        <TextField
          label="City"
          type="text"
          value={event.city}
          onChange={(e) => setEvent({ ...event, city: e.target.value })}
        />
        <FormControl>
          <InputLabel id="event-category">Category</InputLabel>
          <Select
            labelId="event-category"
            label="Category"
            value={event.category}
            onChange={(e) => setEvent({ ...event, category: e.target.value })}
          >
            <MenuItem value="concert">Concert</MenuItem>
            <MenuItem value="art">Art</MenuItem>
            <MenuItem value="entertainment">Entertainment</MenuItem>
            <MenuItem value="show">Show</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Location"
          value={event.venue}
          type="text"
          style={{ width: "90%" }}
        />

        <TextField
          label="Organizer"
          type="text"
          value={event.organizer}
          onChange={(e) => setEvent({ ...event, organizer: e.target.value })}
        />
        <TextField
          label="Price"
          type="text"
          value={event.price}
          onChange={(e) => setEvent({ ...event, price: e.target.value })}
        />
        <TextField
          label="Venue"
          type="text"
          value={event.venue}
          onChange={(e) => setEvent({ ...event, venue: e.target.value })}
        />
        <div className="event-date">
          <TextField
            type="date"
            value={
              event.date instanceof Date
                ? event.date.toISOString().split("T")[0]
                : event.date && !isNaN(Date.parse(event.date))
                ? new Date(event.date).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) =>
              setEvent({
                ...event,
                date: new Date(e.target.value),
              })
            }
          />
          <TextField
            type="time"
            value={event.time}
            onChange={(e) => setEvent({ ...event, time: e.target.value })}
          />
        </div>

        <TextField
          label="Contact"
          type="text"
          value={event.contact}
          onChange={(e) => setEvent({ ...event, contact: e.target.value })}
        />
        <TextField
          label="Notes"
          value={event.notes}
          onChange={(e) => setEvent({ ...event, notes: e.target.value })}
          multiline
          rows={4}
        />
        <Button type="submit">Update Event</Button>
      </form>
      {/* <form onSubmit={handleSubmit}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          value={event.title}
          onChange={(e) => setEvent({ ...event, title: e.target.value })}
        />
        <label htmlFor="artist">Artist</label>
        <input
          type="text"
          value={event.artist}
          onChange={(e) => setEvent({ ...event, artist: e.target.value })}
        />
        <label htmlFor="date">Date</label>
        <input
          type="date"
          value={event.date.toDate().toISOString().split("T")[0]}
          onChange={(e) =>
            setEvent({
              ...event,
              date: new Date(e.target.value),
            })
          }
        />
        {/* Add more inputs for other event fields */}
    </>
  );
}

export default EditEventForm;
