import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { signOut } from "./auth";

function NavBar() {
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut();
    navigate("/");
  };
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Toolbar>
        <Link to="/">
          <img
            alt="logo"
            src={require("../src/assets/arabicnuunpng.png")}
            style={{ width: 165, height: "auto", margin: "20px" }}
          />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        {userLoggedIn && (
          <Button
            component={Link}
            to="/events"
            sx={{ color: "var(--secondary-color)" }}
          >
            Events
          </Button>
        )}

        {userLoggedIn ? (
          <Button
            sx={{ color: "var(--secondary-color)" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        ) : (
          <Button
            sx={{ color: "var( --secondary-color)" }}
            component={Link}
            to="/login"
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default NavBar;
