import { Typography } from "@mui/material";
import { Button } from "@mui/material";

function HomePage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "space-between",
        padding: "0 20px",
      }}
    >
      <div
        style={{
          flex: "0 0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            color: "var(--secondary-color)",
            fontFamily: "passion one",
            fontSize: "3rem",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          UNCOVER YOUR NEXT <br /> FAVORITE EVENT.
        </Typography>

        <Button
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--secondary-color)",
            fontWeight: "bold",
            fontSize: ".8rem",
            marginTop: "15px",
          }}
          href="https://apps.apple.com"
        >
          GET THE APP
        </Button>
      </div>
      <div
        style={{
          flex: "0 0 auto",
          marginLeft: "20px",
        }}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      {/* <Typography
        sx={{
          color: "#eef272",
          fontFamily: "passion one",
          fontSize: "4rem",
        }}
        variant="h1"
      >
        COMING SOON
      </Typography> */}
      {/* <img
        src={require("../src/assets/Splash.gif")}
        alt="logo animation"
        style={{ width: " auto", height: 700 }}
      ></img> */}

      {/* <Button
        sx={{ color: "#eef272" }}
        variant="outlined"
        href="https://apps.apple.com"
      >
        Download on the App Store
      </Button>
      <Button
        sx={{ color: "#eef272" }}
        variant="outlined"
        href="https://play.google.com/store"
      >
        Get it on Google Play
      </Button> */}
    </div>
  );
}
export default HomePage;
