import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import { db } from "./firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";

function EventForm() {
  const [artist, setArtist] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState(null);
  const [organizer, setOrganizer] = useState("");
  const [price, setPrice] = useState("");
  const [venue, setVenue] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [contact, setContact] = useState("");
  const [notes, setNotes] = useState("");
  const inputRef = useRef();
  const fileInputRef = useRef();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(
        new GeoPoint(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        )
      );
      // also needs to setCity and setVenue from the place object
      // Extract city and state from the place object
      let city, state;
      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
      });
      setCity(`${city}, ${state}`);
      setVenue(place.name);
      setAddress(place.formatted_address.split(",")[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      console.log("No file selected");
      return;
    }

    // Create a storage reference
    const storage = getStorage();
    const storageRef = ref(storage, `images/${image.name}`);
    // Upload the image to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error.message);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        const dateTimeString = `${date}T${time}`;
        const timestamp = Timestamp.fromDate(new Date(dateTimeString));

        const eventData = {
          artist,
          title,
          image: downloadURL,
          address,
          city,
          category,
          location,
          organizer,
          price,
          venue,
          date: timestamp,
          contact,
          notes,
        };

        await db.collection("events1").add(eventData);

        setArtist("");
        setTitle("");
        setImage(null);
        setAddress("");
        setLocation("");
        setCity("");
        setCategory("");
        setOrganizer("");
        setPrice("");
        setVenue("");
        setDate("");
        setTime("");
        setContact("");
        setNotes("");
      }
    );

    fileInputRef.current.value = "";
  };

  return (
    <>
      <Typography
        align="center"
        sx={{
          fontFamily: "passion one",
          color: "var(--primary-color)",
          marginTop: "20px",
          marginBottom: "20px",
          fontSize: "1.5rem",
        }}
      >
        EVENT SUBMISSION FORM
      </Typography>
      <form
        onSubmit={handleSubmit}
        className="event-form"
        style={{
          padding: "50px",
        }}
      >
        <TextField
          label="Artist"
          type="text"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
        />
        <TextField
          label="Title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        {/* <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          multiline
          rows={4}
        /> */}
        {/* <TextField
          label="City"
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)} //needs to to be set by handlePlaceChanged
        /> */}

        <FormControl>
          {/* needs to be commented out for now*/}
          <InputLabel id="event-category">Category</InputLabel>
          <Select
            labelId="event-category"
            label="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <MenuItem value="concert">Concert</MenuItem>
            <MenuItem value="art">Art</MenuItem>
            <MenuItem value="entertainment">Entertainment</MenuItem>
            <MenuItem value="show">Show</MenuItem>
          </Select>
        </FormControl>

        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <TextField label="Venue" type="text" style={{ width: "90%" }} />
        </StandaloneSearchBox>

        <TextField
          label="Organizer"
          type="text"
          value={organizer}
          onChange={(e) => setOrganizer(e.target.value)}
        />
        <TextField
          label="Starting price"
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        {/* <TextField
          label="Venue"
          type="text"
          value={venue}
          onChange={(e) => setVenue(e.target.value)} //needs to be set by handlePlaceChanged
        /> */}
        <div className="event-date">
          <TextField
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <TextField
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>

        <TextField
          label="Contact"
          type="text"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={4}
        />
        <Button type="submit">Add Event</Button>
      </form>
    </>
  );
}

export default EventForm;
